<template>
    <h1 style="margin : 30px;">Redirecting to application download page</h1>
</template>
<script>
export default {
    mounted() {
        window.location.href =
            'https://play.google.com/store/apps/details?id=com.pyaesoneshin';
    },
};
</script>
